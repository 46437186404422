import styled from 'styled-components';

export const Background = styled.div`
    background-color: var(--blue-100);
`;

export const Container = styled.div`
    display: grid;
    grid-template-columns: 41.5rem 1fr;
    column-gap: 20px;

    max-width: 60rem;
    width: 100%;

    margin: 0 auto;
    padding: 3.75rem 2rem 4.75rem;

    button {
        display: block;

        max-width: 16rem;
        width: 100%;
        height: 3.5rem;
        margin-left: auto;
        background: var(--red-400);

        border-radius: 60px;
        border: none;

        font: var(--body-sm-600);
        color: var(--white);
    }

    @media (max-width: 62rem) {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;

        button {
            max-width: 100%;

            margin: 0 auto;
        }
    }
`;

export const Info = styled.div`
    h3 {
        margin-bottom: 24px;

        font: var(--heading-md-600);
        font-size: 24px;
        letter-spacing: -0.02em;
        color: var(--blue-800);

        opacity: 0.5;
    }

    p {
        display: flex;
        flex-direction: column;

        margin-bottom: 40px;

        font-weight: 400;
        letter-spacing: -0.01em;
        line-height: 150%;
        color: var(--blue-800);

        strong {
            font-weight: 400;
            letter-spacing: -0.01em;
            line-height: 150%;
            color: var(--blue-600);
        }
    }
`;
