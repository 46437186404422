import styled, { css } from 'styled-components';

export const Container = styled.button`
    height: 24px;
    width: 24px;
    background: transparent;

    padding: 0;

    position: absolute;
    right: 32px;
    z-index: 10;

    border: none;
    cursor: pointer;
    box-shadow: none;

    &:focus {
        outline: none;
    }

    @media (min-width: 62rem) {
        display: none;
    }

    & div {
        position: relative;
        z-index: 1;

        display: block;

        height: 2px;
        width: 18px;
        background: black;

        margin-bottom: 5px;

        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
            opacity 0.55s ease;

        &:nth-child(1) {
            transform-origin: 0% 0%;
        }

        &:nth-child(3) {
            transform-origin: 0% 100%;
        }

        ${(props) =>
            props.open &&
            css`
                opacity: 1;
                transform: rotate(45deg) translate(0px, -3px);

                &:nth-child(3) {
                    opacity: 0;
                    transform: rotate(0deg) scale(0.2, 0.2);
                }

                &:nth-child(2) {
                    transform: rotate(-45deg) translate(0, 0px);
                }
            `}
    }
`;
