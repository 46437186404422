import styled from 'styled-components';

import bgImage from '../../../assets/partners/hero-bg.png';

export const BackgroundColor = styled.div`
    padding-bottom: 2.5rem;
    background-color: var(--blue-100);

    p {
        display: none;

        padding: 1.5rem 2rem 1.25rem;

        font: var(--body-md-400);
        font-size: 1.25rem;
        line-height: 160%;
        letter-spacing: -0.02em;
        color: var(--blue-800);
    }

    @media (max-width: 62rem) {
        p {
            display: block;
        }
    }
`;

export const Background = styled.div`
    display: flex;
    align-items: flex-end;

    height: 45rem;

    background-image: url(${bgImage});
    background-position: 0 36%;
    background-size: cover;

    @media (max-width: 62rem) {
        height: 19.25rem;

        background-position: 40% 36%;
    }
`;

export const Container = styled.div`
    max-width: 55.75rem;
    width: 100%;

    margin: 0 auto;
    padding-bottom: 8.5rem;

    h2 {
        margin-bottom: 1rem;

        font: var(--heading-md-600);
        font-size: 1.875rem;
        line-height: 113%;
        letter-spacing: -0.03em;
        color: #ffffff;
    }

    p {
        font: var(--body-md-400);
        font-size: 1.25rem;
        line-height: 160%;
        letter-spacing: -0.02em;
        color: #ffffff;
    }

    @media (max-width: 62rem) {
        padding: 0 2rem 1.5rem;

        h2 {
            font: var(--heading-md-600);
            font-size: 1.5rem;
            line-height: 142%;

            margin-bottom: 0;
        }

        p {
            display: none;
        }
    }
`;

export const PartnersBox = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 4.25rem;
    column-gap: 8.75rem;

    max-width: 55.75rem;
    width: 100%;

    margin: -100px auto 0;
    padding: 4rem 3rem 3.75rem;
    background: var(--white);

    border-radius: 20px;

    img {
        max-height: 36px;
        max-width: 88px;
    }

    @media (max-width: 62rem) {
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 3rem;
        row-gap: 4.5rem;

        max-width: 42rem;
        margin: 0 auto;
        padding: 3.25rem 3rem 3.75rem;
    }

    @media (max-width: 36rem) {
        grid-template-columns: 1fr 1fr;
        column-gap: 3rem;
        row-gap: 4.5rem;

        max-width: 19.75rem;
        margin: 0 auto;
        padding: 3.25rem 3rem 3.75rem;
    }
`;
