import { React, useEffect } from 'react';
import { animateScroll } from 'react-scroll';

import { HeroSection } from '../components/contact/HeroSection';
import { FormSection } from '../components/contact/FormSection';

import { scrollConstants } from '../helpers/animate-scroll';

export const Contact = () => {
    useEffect(() => {
        animateScroll.scrollToTop({
            duration: scrollConstants.duration
        });
    }, [location.pathname]);

    return (
        <>
            <HeroSection />
            <FormSection />
        </>
    );
};
