import { React, useEffect } from 'react';
import { animateScroll } from 'react-scroll';

import { HeroSection } from '../components/home/HeroSection';
import { NumbersSection } from '../components/home/NumbersSection';
import { SolutionsSection } from '../components/home/SolutionsSection';
import { FormatsSection } from '../components/home/FormatsSection';
import { CampaignSection } from '../components/home/CampaignSection';
import { PlainSection } from '../components/home/PlainSection';

import { scrollConstants } from '../helpers/animate-scroll';

export const Home = () => {
    useEffect(() => {
        animateScroll.scrollToTop({
            duration: scrollConstants.duration
        });
    }, [location.pathname]);

    return (
        <>
            <HeroSection />
            <NumbersSection />
            <SolutionsSection />
            <FormatsSection />
            <CampaignSection />
            <PlainSection />
        </>
    );
};
