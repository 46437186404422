import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { Header } from './components/Header';
import { Footer } from './components/Footer';

import { Home } from './pages/Home';
import { Partners } from './pages/Partners';
import { Contact } from './pages/Contact';

function App() {
    return (
        <>
            <Router>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/partners/" element={<Partners />} />
                    <Route path="/contato/" element={<Contact />} />
                </Routes>
                <Footer />
            </Router>
        </>
    );
}

export default App;
