import styled from 'styled-components';

export const Background = styled.div`
    display: flex;
    justify-content: center;
    background: var(--blue-400);
    width: 100%;

    @media (max-width: 768px) {
        justify-content: flex-start;
    }
`;

export const Container = styled.div`
    overflow-x: hidden;

    max-width: 86.625rem;
    width: 100%;

    margin-top: 180px;
    margin-right: -200px;
    padding: 3.875rem 2rem 9rem;

    .carousel {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .carousel-content {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        height: 39rem;

        h2 {
            margin-bottom: 1rem;

            font: var(--heading-xs-700);
            color: var(--white);
        }

        p {
            margin-bottom: 2rem;

            font: var(--body-lg-600);
            color: var(--white);
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            gap: 0.5rem;

            margin-left: 2.5rem;
            margin-bottom: 2rem;

            li {
                flex-grow: 1;
                min-width: 336px;

                font: var(--body-sm-400);
                letter-spacing: -0.04rem;
                color: var(--white);
            }

            li:nth-child(even) {
                min-width: 336px;
            }

            li:nth-child(odd) {
                min-width: 348px;
            }
        }

        .logos {
            display: flex;
            gap: 24px;

            margin-bottom: 52px;

            img {
                max-width: 142px;
                height: 36px;
            }
        }

        .carousel-image-mobile {
            display: none;
        }

        .button,
        .button-mobile {
            display: flex;
            align-items: center;
            justify-content: center;

            max-width: 19.5rem;
            width: 100%;
            height: 3.5rem;
            background: var(--red-400);

            margin-bottom: 4.5rem;

            border-radius: 60px;

            font: var(--body-sm-600);
            color: var(--white);

            transition: all 0.2s;

            &:hover {
                filter: opacity(80%);
            }
        }

        .button-mobile {
            display: none;
        }

        .controls {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 496px;
        }

        .next,
        .prev {
            width: 52px;
            height: 20px;

            cursor: pointer;

            path,
            rect {
                fill: var(--yellow-400);
            }
        }

        .disable {
            cursor: auto;

            path,
            rect {
                fill: var(--gray-600);
            }
        }

        .next {
            right: 168px;
            transform: rotate(180deg);
        }

        .bulletPoints {
            display: flex;
            gap: 8px;

            .bullet {
                display: inline-block;

                height: 8px;
                width: 38px;
                background-color: var(--gray-600);

                border-radius: 10px;

                cursor: pointer;
            }

            .active {
                background-color: var(--red-400);
            }
        }
    }

    .large {
        max-width: 45.75rem;
        width: 100%;
    }

    .small {
        max-width: 26rem;
        width: 100%;
    }

    .carousel-image {
        max-width: 36.875rem;
        width: 100%;
    }

    .largeImg {
        max-width: 46rem;
    }

    @media (max-width: 768px) {
        margin-top: 116px;

        .carousel {
            flex-direction: column;

            .carousel-content {
                height: auto;

                h1 {
                    margin-bottom: 1.5rem;

                    font-size: 42px;
                }

                .carousel-image-mobile {
                    display: block;
                }

                .button-mobile {
                    display: flex;

                    margin: 0 auto 90px;
                }

                .button {
                    display: none;
                }

                .controls {
                    max-width: 496px;
                    width: 100%;
                }

                .logos {
                    gap: 16px;

                    margin-bottom: 32px;

                    img {
                        max-width: 142px;
                        height: 24px;
                    }
                }
            }

            .carousel-image {
                display: none;
            }
        }
    }
`;
