import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export const MenuMobileButton = ({ onClick, open }) => {
    return (
        <Container open={open} onClick={onClick}>
            <div />
            <div />
            <div />
        </Container>
    );
};

MenuMobileButton.propTypes = {
    onClick: PropTypes.func,
    open: PropTypes.bool
};
