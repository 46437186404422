import React from 'react';
import { Link } from 'react-router-dom';

import { Background, Container } from './styles';

export const PlainSection = () => {
    return (
        <Background>
            <Container>
                <p>
                    Planeje sua campanha, defina e atinja seus objetivos através
                    da Campania.
                </p>

                <Link to="/contato/">Vamos começar?</Link>
            </Container>
        </Background>
    );
};
