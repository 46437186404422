import styled from 'styled-components';

export const Background = styled.div`
    background: var(--blue-400);
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 85.25rem;
    width: 100%;
    height: 6.25rem;

    margin: 0 auto;
    padding: 0 2rem;

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

export const Logo = styled.img`
    max-width: 17.875rem;
    width: 100%;

    @media (max-width: 768px) {
        height: 2.5rem;
    }
`;

export const Nav = styled.nav`
    display: flex;
    gap: 2rem;

    a {
        width: 10.875rem;

        font: var(--body-md-400);
        text-align: center;
        color: var(--white);

        transition: 0.4s all;

        &:hover {
            opacity: 0.72;
        }
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Copy = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    height: 4.5rem;

    font: var(--body-xxs-400);
    color: var(--blue-800);

    & div {
        a {
            color: var(--blue-800);
            text-decoration: underline;
        }

        &::before {
            content: ' - ';
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;

        & div {
            &::before {
                display: none;
            }
        }
    }
`;
