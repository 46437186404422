import styled from 'styled-components';

export const Background = styled.div`
    background-color: var(--red-400);
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 81rem;
    width: 100%;

    margin: 0 auto;
    padding: 5rem 2rem 4.75rem;

    p {
        max-width: 53rem;

        font: var(--body-xl-400);
        color: var(--white);
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;

        max-width: 17.125rem;
        width: 100%;
        height: 4.5rem;

        border: 2px solid #ffffff;
        border-radius: 60px;

        font: var(--body-sm-600);
        color: var(--white);

        transition: all 0.2s;

        &:hover {
            background-color: var(--white);

            color: var(--red-400);
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 40px;

        a {
            max-width: 100%;
            height: 3.5rem;
        }
    }
`;
