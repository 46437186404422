import inventoryImage from '../assets/home/numbers/cards/inventory.svg';
import trafficImage from '../assets/home/numbers/cards/traffic.svg';
import sspsImage from '../assets/home/numbers/cards/ssps.svg';
import publishersImage from '../assets/home/numbers/cards/publishers.svg';

import firstNumbersImage from '../assets/home/numbers/items/first-numbers-image.png';
import secondNumbersImage from '../assets/home/numbers/items/second-numbers-image.png';
import thirdNumbersImage from '../assets/home/numbers/items/third-numbers-image.png';
import navegg from '../assets/home/numbers/items/navegg.svg';
import factual from '../assets/home/numbers/items/factual.svg';
import adsquare from '../assets/home/numbers/items/adsquare.svg';

export const numbersSection = {
    cardsItems: [
        {
            id: 'inventory',
            image: inventoryImage,
            title: '220 Bilhões de\nInventário\nMensal'
        },
        {
            id: 'traffic',
            image: trafficImage,
            title: '95% do\ntráfego\ndigital'
        },
        {
            id: 'ssps',
            image: sspsImage,
            title: 'Mais de\n40 SSPs\nconectadas'
        },
        {
            id: 'publishers',
            image: publishersImage,
            title: 'Mais de 850 mil\nPublishers entre\nSites e Aplicativos'
        }
    ],
    numbersItems: [
        {
            id: '1',
            title: 'Melhor alcance para sua marca',
            paragraphOne:
                'Alcance em todo território nacional. Com presença geolocalizada e hipersegmentada em todos os estados e municípios.',
            points: [],
            paragraphTwo: '',
            logos: [],
            image: firstNumbersImage,
            button: 'Entrar em contato',
            path: '/contato/',
            size: 'small'
        },
        {
            id: '2',
            title: 'Maximize seus retornos com hipersegmentação',
            paragraphOne:
                'Alcance públicos relevantes e maximize seus retornos com nossas soluções de segmentação:',
            points: [
                'Mais de 18 critérios de segmentação proprietários: Geo e Hiperlocal, Demografia, Fornecimento, Tipos de dispositivo e SO, categorias IAB, etc.',
                'Retargeting e storytelling eficientes com a ferramenta Segment Targeting.',
                'DMPs de parceiros e públicos relacionados: Navegg, Factual e Adsquare.',
                'Parceiros de segmentação de terceiros.'
            ],
            paragraphTwo: '',
            logos: [],
            image: secondNumbersImage,
            button: 'Entrar em contato',
            path: '/contato/',
            size: 'large'
        },
        {
            id: '3',
            title: 'Segmentação possível',
            paragraphOne:
                'Através de nossos parceiros, é possível criar mais de 1.400 possibilidades de segmentação.',
            points: [
                'Geolocalização & Hiperlocalização',
                'Sistema Operacional',
                'Demográfico',
                'Operadora',
                'Dispositivos',
                'Categorias IAB'
            ],
            paragraphTwo:
                'e muito mais, tudo isso com os parceiros de dados para melhores targets.',
            logos: [navegg, factual, adsquare],
            image: thirdNumbersImage,
            button: 'Entrar em contato',
            path: '/contato/',
            size: 'large'
        }
    ]
};
