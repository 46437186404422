import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { wrap } from 'popmotion';

import { numbersSection } from '../../../../helpers/home-numbers-items';
import { ReactComponent as Arrow } from '../../../../assets/home/hero/left-arrow.svg';

import { Background, Container } from './styles';

const variants = {
    enter: (direction) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            opacity: 0
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1
    },
    exit: (direction) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0
        };
    }
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
    return Math.abs(offset) * velocity;
};

export const CarouselNumbers = () => {
    const [[page, direction], setPage] = useState([0, 0]);

    const imageIndex = wrap(0, numbersSection.numbersItems.length, page);

    const paginate = (newDirection) => {
        if (newDirection > 0 && page < 2) {
            setPage([page + newDirection, newDirection]);
        }

        if (newDirection < 0 && page > 0) {
            setPage([page + newDirection, newDirection]);
        }

        console.log('page', page, direction);
    };

    return (
        <Background>
            <Container>
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div className="carousel">
                        <motion.div
                            className={
                                numbersSection.numbersItems[imageIndex].size ===
                                'large'
                                    ? 'carousel-content large'
                                    : 'carousel-content small'
                            }
                        >
                            <h2>
                                {numbersSection.numbersItems[imageIndex].title}
                            </h2>

                            <p>
                                {
                                    numbersSection.numbersItems[imageIndex]
                                        .paragraphOne
                                }
                            </p>

                            <ul>
                                {numbersSection.numbersItems[
                                    imageIndex
                                ].points.map((point) => (
                                    <li key={point}>{point}</li>
                                ))}
                            </ul>

                            <p>
                                {
                                    numbersSection.numbersItems[imageIndex]
                                        .paragraphTwo
                                }
                            </p>

                            {numbersSection.numbersItems[imageIndex].logos
                                .length > 0 && (
                                <div className="logos">
                                    {numbersSection.numbersItems[
                                        imageIndex
                                    ].logos.map((logo) => (
                                        <img key={logo} src={logo} />
                                    ))}
                                </div>
                            )}

                            <motion.img
                                key={page}
                                src={
                                    numbersSection.numbersItems[imageIndex]
                                        .image
                                }
                                className={
                                    numbersSection.numbersItems[imageIndex]
                                        .size === 'small'
                                        ? 'carousel-image-mobile largeImg'
                                        : 'carousel-image-mobile'
                                }
                                custom={direction}
                                variants={variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{
                                    x: {
                                        type: 'spring',
                                        stiffness: 300,
                                        damping: 30
                                    },
                                    opacity: { duration: 0.2 }
                                }}
                                drag="x"
                                dragConstraints={{ left: 0, right: 0 }}
                                dragElastic={1}
                                onDragEnd={(e, { offset, velocity }) => {
                                    const swipe = swipePower(
                                        offset.x,
                                        velocity.x
                                    );

                                    if (swipe < -swipeConfidenceThreshold) {
                                        paginate(1);
                                    } else if (
                                        swipe > swipeConfidenceThreshold
                                    ) {
                                        paginate(-1);
                                    }
                                }}
                            />

                            <Link
                                to={
                                    numbersSection.numbersItems[imageIndex].path
                                }
                                className="button-mobile"
                            >
                                {numbersSection.numbersItems[imageIndex].button}
                            </Link>

                            <Link
                                to={
                                    numbersSection.numbersItems[imageIndex].path
                                }
                                className="button"
                            >
                                {numbersSection.numbersItems[imageIndex].button}
                            </Link>

                            <div className="controls">
                                <div
                                    className={
                                        page === 0 ? 'disable prev' : 'prev'
                                    }
                                    onClick={() => paginate(-1)}
                                >
                                    <Arrow />
                                </div>
                                <div className="bulletPoints">
                                    <span
                                        className={
                                            page === 0
                                                ? 'active bullet'
                                                : 'bullet'
                                        }
                                        onClick={() => setPage([0, 0])}
                                    />
                                    <span
                                        className={
                                            page === 1
                                                ? 'active bullet'
                                                : 'bullet'
                                        }
                                        onClick={() => setPage([1, 0])}
                                    />
                                    <span
                                        className={
                                            page === 2
                                                ? 'active bullet'
                                                : 'bullet'
                                        }
                                        onClick={() => setPage([2, 0])}
                                    />
                                </div>

                                <div
                                    className={
                                        page === 2 ? 'disable next' : 'next'
                                    }
                                    onClick={() => paginate(1)}
                                >
                                    <Arrow />
                                </div>
                            </div>
                        </motion.div>

                        <motion.img
                            key={page}
                            src={numbersSection.numbersItems[imageIndex].image}
                            className={
                                numbersSection.numbersItems[imageIndex].size ===
                                'small'
                                    ? 'carousel-image largeImg'
                                    : 'carousel-image'
                            }
                            custom={direction}
                            variants={variants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            transition={{
                                x: {
                                    type: 'spring',
                                    stiffness: 300,
                                    damping: 30
                                },
                                opacity: { duration: 0.2 }
                            }}
                            drag="x"
                            dragConstraints={{ left: 0, right: 0 }}
                            dragElastic={1}
                            onDragEnd={(e, { offset, velocity }) => {
                                const swipe = swipePower(offset.x, velocity.x);

                                if (swipe < -swipeConfidenceThreshold) {
                                    paginate(1);
                                } else if (swipe > swipeConfidenceThreshold) {
                                    paginate(-1);
                                }
                            }}
                        />
                    </motion.div>
                </AnimatePresence>
            </Container>
        </Background>
    );
};
