import React from 'react';

import { numbersSection } from '../../../helpers/home-numbers-items';
import { CarouselNumbers } from './CarouselNumbers';

import { Background, Container, CardsContainer, Card } from './styles';

export const NumbersSection = () => {
    return (
        <Background>
            <Container>
                <CardsContainer>
                    <h2>Campania em números</h2>

                    <div>
                        {numbersSection.cardsItems.map((item) => (
                            <Card key={item.id}>
                                <img src={item.image} alt={item.title} />

                                <p>{item.title}</p>
                            </Card>
                        ))}
                    </div>
                </CardsContainer>
            </Container>

            <CarouselNumbers />
        </Background>
    );
};
