import React, { useRef, useState } from 'react';

import { Form } from '@unform/web';
import { Input } from '../Input';
import { Background, Container, Info } from './styles';

const apiUrl = 'https://api.campania.pro/v1/sendmail';
// const apiUrl = 'http://localhost:3000/dev/v1/send-mail';

export const FormSection = () => {
    const [msgStatus, setMsgStatus] = useState(false);
    const formRef = useRef();

    const handleFormSubmit = async (data) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'text/plain'
            },
            body: JSON.stringify(data)
        };

        try {
            await fetch(apiUrl, requestOptions);
            setMsgStatus(true);
            formRef.current.reset();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Background>
            <Container>
                <Form ref={formRef} onSubmit={handleFormSubmit}>
                    <Input
                        name="name"
                        type="text"
                        label="Nome"
                        placeholder="Digite seu nome completo"
                    />

                    <Input
                        name="email"
                        type="email"
                        label="Email"
                        placeholder="Digite seu email"
                    />

                    <Input
                        name="phone"
                        type="phone"
                        label="Celular"
                        placeholder="Digite seu celular com DDD"
                    />

                    <Input
                        name="message"
                        type="message"
                        label="Mensagem"
                        placeholder="Digite sua mensagem"
                    />

                    {msgStatus === true && (
                        <span>Mensagem enviada com sucesso!</span>
                    )}

                    <button type="submit">Enviar</button>
                </Form>

                <Info>
                    <h3>Nossos contatos</h3>

                    <p>
                        <strong>Email</strong>
                        comercial@campania.pro
                    </p>

                    <p>
                        <strong>Endereço</strong>
                        Av. Paulista, 807. Andar 22.
                        <br />
                        São Paulo - SP
                    </p>
                </Info>
            </Container>
        </Background>
    );
};
