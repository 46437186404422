import styled from 'styled-components';

export const Background = styled.div`
    background: var(--blue-100);
`;

export const Container = styled.div`
    overflow-x: hidden;

    max-width: 83.25rem;
    width: 100%;

    margin: 0 auto;
    padding: 3.875rem 2rem 6.25rem;

    .carousel {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .carousel-content {
        position: relative;

        display: flex;
        flex-direction: column;
        justify-content: center;

        max-width: 31.875rem;
        width: 100%;
        height: 35.75rem;

        h1 {
            margin-bottom: 2.25rem;

            font: var(--heading-xl-700);
        }

        .carousel-image-mobile {
            display: none;
        }

        .button,
        .button-mobile {
            display: flex;
            align-items: center;
            justify-content: center;

            max-width: 19.5rem;
            width: 100%;
            height: 3.5rem;

            background: var(--blue-400);

            border-radius: 60px;

            font: var(--body-sm-600);
            color: var(--white);

            transition: all 0.2s;

            &:hover {
                filter: opacity(80%);
            }
        }

        .button-mobile {
            display: none;
        }

        .controls {
            position: absolute;
            bottom: 0;

            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
        }

        .next,
        .prev {
            width: 52px;
            height: 20px;

            cursor: pointer;

            path,
            rect {
                fill: var(--blue-400);
            }
        }

        .disable {
            cursor: auto;

            path,
            rect {
                fill: var(--gray-600);
            }
        }

        .next {
            right: 0;
            transform: rotate(180deg);
        }

        .bulletPoints {
            display: flex;
            gap: 8px;

            .bullet {
                display: inline-block;

                height: 8px;
                width: 38px;
                background-color: var(--gray-600);

                border-radius: 10px;

                cursor: pointer;
            }

            .active {
                background-color: var(--red-400);
            }
        }
    }

    .carousel-image {
        height: 35.75rem;
        width: 100%;
    }

    @media (max-width: 768px) {
        .carousel {
            flex-direction: column;

            .carousel-content {
                height: auto;

                h1 {
                    margin-bottom: 1.5rem;

                    font-size: 42px;
                }

                .carousel-image-mobile {
                    display: block;
                }

                .button-mobile {
                    display: flex;

                    margin: 0 auto 90px;
                }

                .button {
                    display: none;
                }

                .bulletPoints {
                }
            }

            .carousel-image {
                display: none;
            }
        }
    }
`;
