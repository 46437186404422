import React from 'react';

import { Background, Container } from './styles';

export const HeroSection = () => {
    return (
        <Background>
            <Container>
                <p></p>

                <h2>Fale com nossos especialistas</h2>
            </Container>
        </Background>
    );
};
