import { React, useEffect } from 'react';
import { animateScroll } from 'react-scroll';

import { HeroSection } from '../components/partners/HeroSection';
import { ContactSection } from '../components/partners/ContactSection';

import { scrollConstants } from '../helpers/animate-scroll';

export const Partners = () => {
    useEffect(() => {
        animateScroll.scrollToTop({
            duration: scrollConstants.duration
        });
    }, [location.pathname]);

    return (
        <>
            <HeroSection />
            <ContactSection />
        </>
    );
};
