import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { formatsSection } from '../../../helpers/home-formats-items';

import { Background, Container } from './styles';

export const FormatsSection = () => {
    const [selectedTab, setSelectedTab] = useState(formatsSection.tabs[0]);

    return (
        <Background>
            <Container>
                <h2>Principais formatos</h2>

                <div className="tabs">
                    <nav>
                        <ul>
                            {formatsSection.tabs.map((item) => (
                                <div key={item.label}>
                                    <li
                                        className={
                                            item === selectedTab
                                                ? 'selected'
                                                : ''
                                        }
                                        onClick={() => setSelectedTab(item)}
                                    >
                                        {item.label}
                                    </li>
                                    {item === selectedTab && (
                                        <AnimatePresence exitBeforeEnter>
                                            <motion.div
                                                key={
                                                    selectedTab
                                                        ? selectedTab.label
                                                        : 'empty'
                                                }
                                                className="content-formats-mobile"
                                                animate={{ opacity: 1, y: 0 }}
                                                initial={{ opacity: 0, y: 20 }}
                                                exit={{ opacity: 0, y: -20 }}
                                                transition={{ duration: 0.15 }}
                                            >
                                                <h3>{selectedTab.title}</h3>

                                                <img
                                                    src={selectedTab.image}
                                                    alt={selectedTab.title}
                                                />

                                                <p>{selectedTab.description}</p>
                                            </motion.div>
                                        </AnimatePresence>
                                    )}
                                </div>
                            ))}
                        </ul>
                    </nav>
                    <AnimatePresence exitBeforeEnter>
                        <motion.div
                            key={selectedTab ? selectedTab.label : 'empty'}
                            className="content-formats"
                            animate={{ opacity: 1, y: 0 }}
                            initial={{ opacity: 0, y: 20 }}
                            exit={{ opacity: 0, y: -20 }}
                            transition={{ duration: 0.15 }}
                        >
                            <div>
                                <h3>{selectedTab.title}</h3>

                                <p>{selectedTab.description}</p>
                            </div>

                            <img
                                src={selectedTab.image}
                                alt={selectedTab.title}
                            />
                            {selectedTab ? selectedTab.icon : '😋'}
                        </motion.div>
                    </AnimatePresence>
                </div>
            </Container>
        </Background>
    );
};
