import React from 'react';
import { Link } from 'react-router-dom';

import { Background, Container } from './styles';

export const ContactSection = () => {
    return (
        <Background>
            <Container>
                <p>CONTATO</p>

                <h3>Quero falar com um especialista</h3>

                <Link to="/contato/">Entrar em contato</Link>
            </Container>
        </Background>
    );
};
