import styled, { css } from 'styled-components';

export const Background = styled.div`
    position: sticky;
    z-index: 999;
    top: 0;

    width: 100%;
    background: var(--gray-50);
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    max-width: 87.75rem;
    width: 100%;
    height: 6.25rem;

    margin: 0 auto;
    padding: 0 2rem;
`;

export const Logo = styled.img`
    max-width: 17.875rem;
    width: 100%;

    @media (max-width: 62rem) {
        max-width: 12rem;
    }
`;

export const Nav = styled.nav`
    display: flex;
    gap: 2rem;

    a {
        width: 10.875rem;

        font: var(--body-md-400);
        text-align: center;
        color: var(--gray-400);

        transition: 0.4s all;

        &:hover {
            opacity: 0.72;
        }
    }

    @media screen and (max-width: 62rem) {
        position: fixed;
        top: 100px;
        left: 0;
        z-index: 900;

        flex-direction: column;

        height: calc(100vh - 100px);
        width: 100%;
        background: white;

        padding-top: 1.5rem;

        overflow-y: scroll;
        transform: translateY(130%);
        transition: all 0.325s ease-in-out;

        ${(props) =>
            props.open &&
            css`
                transform: translateY(0);
            `}

        a {
            font-weight: 600;
            color: var(--blue-800);
        }
    }
`;

export const ContainerMenuMobile = styled.div``;
