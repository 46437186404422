export const navItems = [
    {
        id: 'partners',
        path: '/partners',
        name: 'Partners'
    },
    {
        id: 'contact',
        path: '/contato',
        name: 'Contato'
    }
];
