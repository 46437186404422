import uol from '../assets/home/campaign/brands/uol.png';
import terra from '../assets/home/campaign/brands/terra.png';
import istoe from '../assets/home/campaign/brands/istoe.png';
import msn from '../assets/home/campaign/brands/msn.png';
import gazeta from '../assets/home/campaign/brands/gazeta.png';
import ge from '../assets/home/campaign/brands/ge.png';
import g1 from '../assets/home/campaign/brands/g1.png';
import spotify from '../assets/home/campaign/brands/spotify.png';
import jp from '../assets/home/campaign/brands/jp.png';
import globo from '../assets/home/campaign/brands/globo.png';
import exame from '../assets/home/campaign/brands/exame.png';
import yahoo from '../assets/home/campaign/brands/yahoo.png';
import antagonista from '../assets/home/campaign/brands/antagonista.png';
import valor from '../assets/home/campaign/brands/valor.png';

import xandr from '../assets/home/campaign/spps/xandr.png';
import rubicon from '../assets/home/campaign/spps/rubicon.png';
import verizon from '../assets/home/campaign/spps/verizon.png';

export const brandsItems = [
    {
        id: 'uol',
        image: uol
    },
    {
        id: 'terra',
        image: terra
    },
    {
        id: 'istoe',
        image: istoe
    },
    {
        id: 'msn',
        image: msn
    },
    {
        id: 'gazeta',
        image: gazeta
    },
    {
        id: 'ge',
        image: ge
    },
    {
        id: 'g1',
        image: g1
    },
    {
        id: 'spotify',
        image: spotify
    },
    {
        id: 'jp',
        image: jp
    },
    {
        id: 'globo',
        image: globo
    },
    {
        id: 'exame',
        image: exame
    },
    {
        id: 'yahoo',
        image: yahoo
    },
    {
        id: 'antagonista',
        image: antagonista
    },
    {
        id: 'valor',
        image: valor
    }
];

export const sppsItems = [
    {
        id: 'xandr',
        image: xandr
    },
    {
        id: 'rubicon',
        image: rubicon
    },
    {
        id: 'verizon',
        image: verizon
    }
];
