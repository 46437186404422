import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { MenuMobileButton } from './MenuMobileButton';

import logo from '../../assets/header/logo-campania.svg';
import { navItems } from '../../helpers/nav-items';

import { Background, Container, Nav, Logo } from './styles';

export const Header = () => {
    const [open, setOpen] = useState(false);

    return (
        <Background>
            <Container>
                <Link to="/">
                    <Logo src={logo} alt="Campania" />
                </Link>

                <MenuMobileButton open={open} onClick={() => setOpen(!open)} />

                <Nav open={open}>
                    {navItems.map((item) => (
                        <Link key={item.id} to={item.path}>
                            {item.name}
                        </Link>
                    ))}
                </Nav>
            </Container>
        </Background>
    );
};
