import styled from 'styled-components';

export const Background = styled.div`
    display: flex;
    justify-content: center;

    background-color: var(--blue-100);
`;

export const Container = styled.div`
    max-width: 76.75rem;
    width: 100%;

    margin-right: -44px;
    padding: 2.5rem 2rem 7.75rem;

    h2 {
        margin-bottom: 3.75rem;

        font: var(--heading-md-600);
    }

    .tabs {
        display: flex;
        gap: 8.75rem;
    }

    nav {
        display: flex;
        flex-direction: column;

        max-width: 27.5rem;
        width: 100%;
    }

    .content-formats,
    .content-formats-mobile {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        max-width: 27.25rem;
        width: 100%;

        font-size: 128px;
        user-select: none;

        h3 {
            margin-bottom: 1rem;

            font: var(--heading-sm-700);
            color: var(--red-400);
        }

        p {
            font: var(--body-md-400);
        }

        img {
            max-width: 26rem;
            width: 100%;
        }
    }

    .content-formats-mobile {
        display: none;
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    li {
        list-style: none;

        padding: 1.5rem 1.5rem 1.5rem 3rem;
        background-color: var(--white);

        border-radius: 19px;
        box-shadow: 0px 18px 52.8537px rgba(215, 228, 249, 0.5);

        font: var(--body-lg-600);

        cursor: pointer;
    }

    li.selected {
        border: 1px solid var(--blue-400);

        color: var(--blue-400);
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 300px;
        background: #fff;
    }

    @media (max-width: 1160px) {
        margin-right: 0;
        padding-left: 2rem;
        padding-right: 2rem;

        .tabs {
            justify-content: center;
        }

        .content-formats {
            display: none;
        }

        .content-formats-mobile {
            display: flex;

            max-width: 36rem;

            h3 {
                margin-top: 2.5rem;
                margin-bottom: 1.5rem;
            }

            img {
                margin-bottom: 1.5rem;
            }

            p {
                margin-bottom: 2rem;
            }
        }

        nav {
            max-width: 36rem;
        }
    }
`;
