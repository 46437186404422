import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import PropTypes from 'prop-types';

import { Container } from './styles';

export const Input = ({ label, type, name, ...rest }) => {
    const inputRef = useRef();

    const { fieldName, registerField } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: (ref) => {
                return ref.current.value;
            },
            setValue: (ref, value) => {
                ref.current.value = value;
            },
            clearValue: (ref) => {
                ref.current.value = '';
            }
        });
    }, [fieldName, registerField]);

    return (
        <Container className={name}>
            <label htmlFor={fieldName}>{label}</label>
            <input
                name={name}
                ref={inputRef}
                type={type}
                placeholder="Type your username"
                {...rest}
            />
        </Container>
    );
};

Input.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string
};
