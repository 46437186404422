import styled from 'styled-components';

export const Background = styled.div`
    background: linear-gradient(
        180deg,
        #ffffff 0%,
        rgba(202, 207, 215, 0.262661) 83.7%,
        rgba(244, 248, 255, 0) 100%
    );
`;

export const Container = styled.div`
    max-width: 74rem;
    width: 100%;

    margin: 0 auto;
    padding: 5.75rem 2rem 6.5rem;

    h2 {
        margin-bottom: 3.75rem;

        font: var(--heading-lg-600);
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;

        max-width: 19.5rem;
        width: 100%;
        height: 3.5rem;

        margin: 0 auto;
        background: var(--blue-400);

        border-radius: 60px;

        font: var(--body-sm-600);
        color: var(--white);

        &:hover {
            filter: opacity(80%);
        }
    }

    @media (max-width: 768px) {
        h2 {
            margin-bottom: 2rem;

            font-size: 2.5rem;
        }
    }
`;

export const Brands = styled.div`
    margin-bottom: 1.75rem;
    padding: 2.25rem 4rem 3rem;
    background-color: var(--blue-200);

    border-radius: 25px;

    & > div {
        display: flex;
        align-items: center;
        gap: 3rem;
    }

    h3 {
        margin-bottom: 2.25rem;

        font: var(--body-xl-700);
        color: var(--blue-400);
    }

    & div > div {
        display: flex;
        gap: 1.5rem;
        flex-wrap: wrap;

        max-width: 50rem;

        img {
            width: 92px;
            height: 80px;
        }
    }

    @media (max-width: 768px) {
        padding: 2.25rem 1.25rem 2.25rem;

        h3 {
            margin-bottom: 1.25rem;
        }

        & > div {
            flex-direction: column;
            gap: 1.25rem;
        }

        & div > div {
            gap: 0.5rem;
            flex-wrap: wrap;

            img {
                width: 83px;
                height: 73px;
            }
        }
    }
`;

export const Spps = styled.div`
    display: flex;
    align-items: center;
    gap: 2rem;

    margin-bottom: 2.75rem;

    & > div {
        display: flex;
        gap: 1.25rem;

        & div {
            padding: 0.625rem 3.25rem;
            background-color: var(--white);

            border-radius: 16px;
        }
    }

    p {
        max-width: 12.25rem;
    }

    @media (max-width: 768px) {
        flex-direction: column;

        & > div {
            flex-direction: column;
        }

        p {
            max-width: 100%;
        }
    }
`;
