import React from 'react';

import { brandsItems } from '../../../helpers/partners-hero-items';

import { BackgroundColor, Background, Container, PartnersBox } from './styles';

export const HeroSection = () => {
    return (
        <BackgroundColor>
            <Background>
                <Container>
                    <h2>Um portfolio de alto nível com mais de 40 SSPs</h2>

                    <p>
                        Temos parceria com SSPs, Ad Exchanges líderes globais.
                        Geo localizações, categorias, formatos de Ad - Temos
                        qualquer tipo de tráfego que você deseja para alcançar e
                        escalar sob demanda.
                    </p>
                </Container>
            </Background>

            <p>
                Temos parceria com SSPs, Ad Exchanges líderes globais. Geo
                localizações, categorias, formatos de Ad - Temos qualquer tipo
                de tráfego que você deseja para alcançar e escalar sob demanda.
            </p>

            <PartnersBox>
                {brandsItems.map((item) => (
                    <img key={item.id} src={item.image} alt={item.id} />
                ))}
            </PartnersBox>
        </BackgroundColor>
    );
};
