import React from 'react';
import { Link } from 'react-router-dom';

import { brandsItems, sppsItems } from '../../../helpers/home-campaign-items';

import { Background, Container, Brands, Spps } from './styles';

export const CampaignSection = () => {
    return (
        <Background>
            <Container>
                <h2>Sua campanha visualizada por todos</h2>

                <Brands>
                    <h3>Onde sua marca vai aparecer</h3>

                    <div>
                        <div>
                            {brandsItems.map((item) => (
                                <img
                                    key={item.id}
                                    src={item.image}
                                    alt={item.id}
                                />
                            ))}
                        </div>

                        <p>e +840mil entre Sites e Apps</p>
                    </div>
                </Brands>

                <Spps>
                    <div>
                        {sppsItems.map((item) => (
                            <div key={item.id}>
                                <img src={item.image} alt={item.id} />
                            </div>
                        ))}
                    </div>

                    <p>e + 40 SSPs prontas para entregar suas campanhas</p>
                </Spps>

                <Link to="/partners/">Conhecer todos</Link>
            </Container>
        </Background>
    );
};
