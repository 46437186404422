import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/footer/logo-campania-footer.svg';
import { navItems } from '../../helpers/nav-items';

import { Background, Container, Nav, Logo, Copy } from './styles';

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <>
            <Background>
                <Container>
                    <Link to="/">
                        <Logo src={logo} alt="Campania" />
                    </Link>

                    <Nav>
                        {navItems.map((item) => (
                            <Link key={item.id} to={item.path}>
                                {item.name}
                            </Link>
                        ))}
                    </Nav>
                </Container>
            </Background>

            <Copy>
                COPYRIGHT CAMPANIA {currentYear}
                <div>
                    <a href="/">TERMOS DE USO</a> E{' '}
                    <a href="/">POLÍTICA DE PRIVACIDADE</a>
                </div>
            </Copy>
        </>
    );
};
