import styled from 'styled-components';

export const Container = styled.fieldset`
    display: inline;

    max-width: 41.5rem;
    width: 100%;
    margin-bottom: 20px;
    padding: 0.5rem 1rem;
    background: #ffffff;

    border: 1px solid #ccd2e9;
    border-radius: 24px;

    &.email {
        max-width: 22.5rem;
        margin-right: 2.25rem;
    }

    &.phone {
        max-width: 16.75rem;
    }

    &.message {
        height: 12.375rem;
    }

    label {
        display: block;

        font-style: normal;
        font-weight: 400;
        line-height: 162%;
        color: var(--blue-900);

        opacity: 0.35;
    }

    input {
        width: 100%;
        background-color: transparent;

        border: none;
        outline: none;

        font: var(--body-xs-700);
        font-weight: 400;
        line-height: 162%;
        color: var(--blue-900);

        &::placeholder {
            font: var(--body-xs-700);
            font-weight: 400;
            line-height: 162%;
            color: var(--blue-900);
        }
    }

    @media (max-width: 62rem) {
        &.email,
        &.phone {
            max-width: 41.5rem;
        }
    }
`;
