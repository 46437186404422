import styled from 'styled-components';

export const Background = styled.div`
    display: flex;
    justify-content: center;

    padding: 2.5rem 0 5.5rem;
    background-color: var(--blue-100);
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    max-width: 70rem;
    width: 100%;

    margin: 0 2rem;
    padding: 4.5rem 2rem 4.75rem;
    background-color: var(--blue-800);

    border-radius: 24px;

    color: white;

    p {
        margin-bottom: 1rem;

        font: var(--body-md-400);
        font-size: 1.25rem;
        line-height: 160%;
        letter-spacing: 0.02em;
        text-align: center;
        color: var(--white);

        opacity: 0.5;
    }

    h3 {
        margin-bottom: 2.25rem;

        font: var(--heading-md-600);
        line-height: 111%;
        text-align: center;
        letter-spacing: -0.03em;
        color: var(--white);
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;

        max-width: 16rem;
        width: 100%;
        height: 3.5rem;

        background: var(--red-400);
        border-radius: 60px;

        font: var(--body-sm-600);
        color: var(--white);
    }

    @media (max-width: 62rem) {
        padding: 2rem 1.25rem 2rem;

        p {
            font-size: 15px;
        }

        h3 {
            margin-bottom: 2rem;

            font-size: 26px;
            line-height: 115%;
        }
    }
`;
