import first from '../assets/home/formats/first.png';
import intext from '../assets/home/formats/intext.png';
import scroll from '../assets/home/formats/scroll.png';
import side from '../assets/home/formats/side.png';
import under from '../assets/home/formats/under.png';

export const formatsSection = {
    tabs: [
        {
            id: 'scroll',
            label: 'Scroll Banner',
            title: 'Scroll Banner',
            description:
                'Surge entre os scrolls de uma página, mostrando um anúncio de um lado até outro da página.',
            image: scroll
        },
        {
            id: 'first',
            label: 'First Banner',
            title: 'First Banner',
            description:
                'É um conteúdo veiculado no topo da página, antes ou depois do menu principal.',
            image: first
        },
        {
            id: 'intext',
            label: 'In-text Banner',
            title: 'In-text Banner',
            description:
                'Aparece no meio do texto, entre um parágrafo e o outro. É exibido apenas nas páginas internas do site.',
            image: intext
        },
        {
            id: 'side',
            label: 'Side Banner',
            title: 'Side Banner',
            description:
                'É um formato exibido na lateral direita ou na lateral esquerda da página.',
            image: side
        },
        {
            id: 'under',
            label: 'Under Banner',
            title: 'Under Banner',
            description:
                'É um formato exibido no rodapé da página e acompanha o usuário à medida que ele navega pelo conteúdo.',
            image: under
        }
    ]
};
