import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    /***  COLORS ***/

    --blue-100: #F3F7FF;
    --blue-200: #E8EEFF;
    --blue-400: #3760FF;
    --blue-600: #0059F7;
    --blue-800: #1F1658;
    --blue-900: #10375C;

    --red-400: #FF5D27;

    --yellow-400: #FCC915;

    --gray-50: #FEFFFF;
    --gray-400: #A5AAB0;
    --gray-600: #D0D0D0;
;

    --white: #FFFFFF;
    --black: #000000;

    /***  TYPOGRAPHY ***/

    --heading-xl-700: 700 3.1875rem/120% 'Sora', sans-serif;
    --heading-lg-600: 600 3.125rem/128% 'Sora', sans-serif;
    --heading-md-600: 600 3rem/133% 'Sora', sans-serif;
    --heading-sm-700: 700 2.5625rem/100% 'Sora', sans-serif;
    --heading-xs-700: 700 2.25rem/45px 'Sora', sans-serif;

    --body-xl-700: 700 1.6875rem/130% 'Sora', sans-serif;
    --body-xl-400: 400 1.6875rem/146% 'Sora', sans-serif;
    --body-lg-600: 600 1.3125rem/143% 'Sora', sans-serif;
    --body-md-400: 400 1.1875rem/158% 'Sora', sans-serif;
    --body-sm-600: 600 1.125rem/140% 'Sora', sans-serif;
    --body-sm-400: 400 1.125rem/140% 'Sora', sans-serif;
    --body-xs-700: 700 1rem/125% 'Sora', sans-serif;
    --body-xxs-400: 400 0.75rem/180% 'Roboto', sans-serif;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    scroll-behavior: smooth;
  }

  body, h1, h2, h3, h4, h5, h6, p, strong {
    font: var(--body-xs-700);
    color: var(--blue-800);
  }

  a {
    text-decoration: none;
  }

  button {
    outline: none;
  }

  img {
    width: 100%;
  }
`;
