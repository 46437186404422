import video from '../assets/home/solutions/video.svg';
import display from '../assets/home/solutions/display.svg';
import ctv from '../assets/home/solutions/ctv.svg';
import native from '../assets/home/solutions/native.svg';
import audio from '../assets/home/solutions/audio.svg';

export const solutionsItems = [
    {
        id: 'video',
        image: video,
        title: 'Video'
    },
    {
        id: 'display',
        image: display,
        title: 'Display Ads'
    },
    {
        id: 'ctv',
        image: ctv,
        title: 'CTV'
    },
    {
        id: 'native',
        image: native,
        title: 'Native'
    },
    {
        id: 'audio',
        image: audio,
        title: 'Audio Ads'
    }
];
