import firstFeaturedImage from '../assets/home/hero/first-featured-image.png';
import secondFeaturedImage from '../assets/home/hero/second-featured-image.png';
import thirdFeaturedImage from '../assets/home/hero/third-featured-image.png';

export const heroItems = [
    {
        id: '1',
        title: 'Programática fácil para todos os perfis de anunciante.',
        image: firstFeaturedImage,
        button: 'Entrar em contato',
        path: '/contato/'
    },
    {
        id: '2',
        title: 'Atingimos mais de 95% do inventário digital em diversas plataformas.',
        image: secondFeaturedImage,
        button: 'Conhecer nossos Parceiros',
        path: '/partners/'
    },
    {
        id: '3',
        title: 'Mais de 1.400 possibilidades de hipersegmentação da sua campanha.',
        image: thirdFeaturedImage,
        button: 'Entrar em contato',
        path: '/contato/'
    }
];
