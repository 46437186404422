import styled from 'styled-components';

import heroBg from '../../../assets/contact/hero-bg.png';

export const Background = styled.div`
    background-image: url('${heroBg}');
    background-size: cover;

    @media (max-width: 62rem) {
        background-position-x: 30%;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    max-width: 60rem;
    width: 100%;
    height: 342px;

    margin: 0 auto;
    padding: 1.5rem 2rem 1.75rem;

    p {
        font: var(--body-xs-700);
        font-weight: 400;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: #ffffff;
    }

    h2 {
        font: var(--body-xl-700);
        font-weight: 600;
        font-size: 30px;
        line-height: 113%;
        letter-spacing: -0.03em;
        color: #ffffff;
    }

    @media (max-width: 62rem) {
        height: 304px;

        h2 {
            font-size: 24px;
            line-height: 142%;
        }
    }
`;
