import React from 'react';

import { solutionsItems } from '../../../helpers/home-solutions-items';

import { Background, Container, Card } from './styles';

export const SolutionsSection = () => {
    return (
        <Background id="features">
            <Container>
                <h2>Nossas soluções</h2>

                <p>
                    Entregamos em diversos formatos para sua marca aparecer
                    sempre bem
                </p>

                <div>
                    {solutionsItems.map((item) => (
                        <Card key={item.id}>
                            <img src={item.image} alt={item.title} />

                            <p>{item.title}</p>
                        </Card>
                    ))}
                </div>
            </Container>
        </Background>
    );
};
