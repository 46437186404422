import aol from '../assets/partners/hero/1-aol.png';
import adColony from '../assets/partners/hero/ad-colony.png';
import doubleClick from '../assets/partners/hero/double-click.png';
import inmobi from '../assets/partners/hero/inmobi.png';
import adForm from '../assets/partners/hero/ad-form.png';
import adYouLike from '../assets/partners/hero/ad-you-like.png';
import ligatus from '../assets/partners/hero/ligatus.png';
import mgid from '../assets/partners/hero/mgid.png';
import fyber from '../assets/partners/hero/fyber.png';
import mobfox from '../assets/partners/hero/mobfox.png';
import spotx from '../assets/partners/hero/spotx.png';
import stroer from '../assets/partners/hero/stroer.png';
import mopub from '../assets/partners/hero/mopub.png';
import outbrain from '../assets/partners/hero/outbrain.png';
import passendo from '../assets/partners/hero/passendo.png';
import plista from '../assets/partners/hero/plista.png';
import taboola from '../assets/partners/hero/taboola.png';
import teads from '../assets/partners/hero/teads.png';
import triplelift from '../assets/partners/hero/triplelift.png';
import verizon from '../assets/partners/hero/verizon.png';
import pubMatic from '../assets/partners/hero/pub-matic.png';
import rubicon from '../assets/partners/hero/rubicon.png';
import smaato from '../assets/partners/hero/smaato.png';
import smart from '../assets/partners/hero/smart.png';
import xandr from '../assets/partners/hero/xandr.png';
import intelifi from '../assets/partners/hero/intelifi.png';

export const brandsItems = [
    {
        id: 'aol',
        image: aol
    },
    {
        id: 'adColony',
        image: adColony
    },
    {
        id: 'doubleClick',
        image: doubleClick
    },
    {
        id: 'inmobi',
        image: inmobi
    },
    {
        id: 'adForm',
        image: adForm
    },
    {
        id: 'adYouLike',
        image: adYouLike
    },
    {
        id: 'ligatus',
        image: ligatus
    },
    {
        id: 'mgid',
        image: mgid
    },
    {
        id: 'fyber',
        image: fyber
    },
    {
        id: 'mobfox',
        image: mobfox
    },
    {
        id: 'spotx',
        image: spotx
    },
    {
        id: 'stroer',
        image: stroer
    },
    {
        id: 'mopub',
        image: mopub
    },
    {
        id: 'outbrain',
        image: outbrain
    },
    {
        id: 'passendo',
        image: passendo
    },
    {
        id: 'plista',
        image: plista
    },
    {
        id: 'taboola',
        image: taboola
    },
    {
        id: 'teads',
        image: teads
    },
    {
        id: 'triplelift',
        image: triplelift
    },
    {
        id: 'verizon',
        image: verizon
    },
    {
        id: 'pubMatic',
        image: pubMatic
    },
    {
        id: 'rubicon',
        image: rubicon
    },
    {
        id: 'smaato',
        image: smaato
    },
    {
        id: 'smart',
        image: smart
    },
    {
        id: 'xandr',
        image: xandr
    },
    {
        id: 'intelifi',
        image: intelifi
    }
];
