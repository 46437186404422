import styled from 'styled-components';

export const Background = styled.div`
    background: var(--blue-100);
`;

export const Container = styled.div`
    max-width: 74rem;
    width: 100%;

    margin: 0 auto;
    padding: 6.25rem 2rem 2.5rem;

    h2 {
        margin-bottom: 1.25rem;

        font: var(--heading-md-600);
        letter-spacing: -0.03em;
    }

    & > p {
        margin-bottom: 6rem;

        font: var(--heading-xs-700);
        color: var(--blue-400);
    }

    & > div {
        display: flex;
        justify-content: space-between;
        gap: 20px;
    }

    @media (max-width: 768px) {
        h2 {
            font-size: 2.5rem;
        }

        & > p {
            margin-bottom: 2.5rem;

            font-size: 28px;
            font-weight: 400;
            line-height: 35px;
        }

        & > div {
            overflow-x: scroll;

            gap: 0;

            margin: 0 -32px;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    min-width: 13rem;
    width: 100%;
    padding: 2rem 1.5rem;
    background-color: var(--blue-200);

    border-radius: 16px;

    img {
        width: 64px;
        height: 64px;
    }

    @media (max-width: 768px) {
        margin: 0 16px;

        &:first-child {
            margin-left: 32px;
        }

        &:last-child {
            margin-right: 32px;
        }
    }
`;
