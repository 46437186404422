import styled from 'styled-components';

export const Background = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    background: var(--blue-100);

    @media (max-width: 768px) {
        align-items: flex-start;
    }
`;

export const Container = styled.div`
    overflow-x: hidden;

    max-width: 86.625rem;
    width: 100%;

    margin-right: -200px;
    margin-bottom: -108px;
    padding: 3.875rem 2rem 0;
`;

export const CardsContainer = styled.div`
    max-width: 70.125rem;
    width: 100%;

    & > div {
        display: flex;
        gap: 1rem;
    }

    h2 {
        font: var(--heading-lg-600);
        letter-spacing: -0.03em;

        margin-bottom: 5.75rem;
    }

    @media (max-width: 768px) {
        & > div {
            overflow-x: scroll;

            gap: 0;

            margin-left: -32px;
            margin-right: -32px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        h2 {
            font-size: 2.5rem;

            margin-bottom: 2.5rem;
        }
    }
`;

export const Card = styled.div`
    z-index: 10;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    min-width: 16.75rem;
    background-color: var(--white);

    padding: 1.5rem 2rem 2rem;

    border-radius: 16px;

    img {
        width: 64px;
        height: 64px;
    }

    p {
        max-width: 160px;

        margin-bottom: 1.75rem;

        white-space: pre;
        text-align: center;
        color: var(--blue-400);
    }

    @media (max-width: 768px) {
        width: 100%;

        margin: 0 16px;

        &:first-child {
            margin-left: 32px;
        }

        &:last-child {
            margin-right: 32px;
        }
    }
`;
